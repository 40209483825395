
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import TimetableFilter from '@/components/TimetableFilter.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import Vue from 'vue';

import Modal from './Modal.vue';


import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { resourcePermission } from '@/components/mixins/permissions/resource';

import moment from 'moment';
import { EXECUTION_QUEUE_RESOURCE_MODAL_KEY } from '../constants';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters, mapState } from 'vuex';

export default mixins(
	restApi,
	resourcePermission,
).extend({
	components: {
		Table,
		FilterBar,
		TimetableFilter,
		FilterIcon,
        Modal,
		SelectFilter,
		BackgroundView,
		InteractionMenu,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'ExecutionQueuePodModal',
    props: {
		dataModal: {
			type: Object,
		},
	},
	data() {
		return {
			showAlert: {
				active: false,
				data: {},
			},
			batchStop: false,
			isLoadingPage: true,
			isLoadingTable: true,
			executionsSelected: [],
			modalBus: new Vue(),

			EXECUTION_QUEUE_RESOURCE_MODAL_KEY,
			listExecutions: [],
			execucoesParando: JSON.parse(WebStorage.getItem('execucoesParando')) || [],
			intervalTimer: null,
			refreshInterval: null,
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		workspace() {
			return this.$store.getters.workspace;
		},

		allExecutionsSelected() {
			return this.executionsAbleToSelect == this.executionsSelected.length;
		},
		partialExecutionsSelected() {
			return this.executionsAbleToSelect != this.executionsSelected.length && this.executionsSelected.length > 0;
		},
		executionsAbleToSelect() {
			return this.listExecutions?.length;
		},

		discardModalText() {
			return this.$locale.baseText('Continuar Executando');
		},
		keepCloseText() {
			return 'Interromper Execução';
		},
		keepModalText() {
			return this.$locale.baseText('Parar Execução');
		},
		alertNotificationTitle() {
			return this.batchStop ? this.$locale.baseText('Parar Execuções dos Fluxos') : this.$locale.baseText('Parar Execução do Fluxo ');
		},
		alertNotificationDescription() {
			if(this.batchStop) {
				return `A <b>interrupção</b> da execução  de um fluxo pode ocasionar <b>inconsistências</b> nos <b>dados</b> do sistema de destino. Caso queira, poderá <b>parar</b> a execução na <b>transição</b> para o <b>proximo conector</b> ou <b>interromper imediatamente</b> sem aguardar que a <b>transição</b> ocorra. O que você deseja fazer?`;

			} else {
				return `A <b>interrupção</b> da execução  de um fluxo pode ocasionar <b>inconsistências</b> nos <b>dados</b> do sistema de destino. Caso queira, poderá <b>parar</b> a execução na <b>transição</b> para o <b>proximo conector</b> ou <b>interromper imediatamente</b> sem aguardar que a <b>transição</b> ocorra. O que você deseja fazer?`;
			}
		},
		
		emptyList() {
			return 'Não há execuções na fila. A medida que forem adicionadas, as informações serão apresentadas aqui.'
		},

		executionsStopping() {
			return this.executionsStoppingLength == 1 ? 'Execução parando' : 'Execuções parando';
		},
		executionsStoppingLength() {
			const stoppingLength = this.listExecutions?.filter(function(execucao) {
				return execucao.status == 'Parando';
			});

			return stoppingLength?.length;
		},
		selectedExecution() {
			return this.executionsSelected.length == 1 ? 'Execução selecionada' : 'Execuções selecionadas';
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'verifyUserActive', 'getUserPermissionById']),

		closeAlert() {
			this.showAlert.active = false;
			this.batchStop = false;
			this.showAlert.data = {};
		},

		async stopExecution(options: {stopImmediate: Boolean}) {
			this.showAlert.active = false;
			let payload = {};
			if (this.batchStop) {
				payload.id = [...this.executionsSelected];
			} else {
				payload.id = [this.showAlert.data.id];
			}
			payload.id.forEach(async (executionId) =>{
				let execucao = this.listExecutions.find(execution => execution.execucaoId == executionId);
				const oldStatus = execucao.status;
				try {
					execucao.isStoping = true;
					execucao.status = 'Parando';

					if (options?.stopImmediate) {
						await this.restApi().stopImmediateExecutionById(execucao.workspace.id, executionId);
					} else {
						await this.restApi().stopExecutionById(execucao.workspace.id, executionId);
					}

					this.execucoesParando.push(execucao);
					WebStorage.setItem('execucoesParando', JSON.stringify(this.execucoesParando));
				} catch (e) {
					execucao.status = oldStatus;
					execucao.isStoping = false;
					console.error(e);
				}
			});

			this.batchStop = false;
			this.executionsSelected = [];
		},

		async initPageExecutionQueueModal() {
			await this.getExecutionQueue()
		},

		openModalAlert(execution) {
			this.showAlert.active = true;
			this.showAlert.data.id = execution.execucaoId;
		},
		openAlertBatchStop() {
			this.batchStop = true;
			this.showAlert.active = true;
		},

		formatarData(dataString) {
			const dataMomentUTC = moment.utc(dataString);
			const dataFormatada =  moment(dataMomentUTC).local().format("HH[h]:mm[min]");
			return dataFormatada;
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatarHoraMinuto(timestamp) {
			if (timestamp === undefined || timestamp === null || timestamp === '') {
				return '';
			}

			const formatoDesejado = 'HH[h]:mm[min]';
			const partesTimestamp = timestamp.split(':');
			const momentoFormatado = moment({ hour: partesTimestamp[0], minute: partesTimestamp[1] }).format(formatoDesejado);
			return momentoFormatado;
		},

		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		addExecutionToSelect(execution) {
			if(execution.status == 'Parando') return;

			if (this.verifyExecutionSelected(execution.execucaoId)) {
				removeItemFromArrayByValue(this.executionsSelected, execution.execucaoId);
			} else {
				this.executionsSelected.push(execution.execucaoId);
			}
		},
		verifyExecutionSelected(verifyExecutionId) {
			return this.executionsSelected.find(execucaoId => execucaoId === verifyExecutionId);
		},
		selectAllExecutions() {
			if (this.partialExecutionsSelected || this.allExecutionsSelected) {
				this.executionsSelected = [];
			} else {
				this.listExecutions.forEach((execution) =>{
					if (execution.status == 'Parando') return;
					this.addExecutionToSelect(execution);
				});
			}
		},

		async getExecutionQueue() {
			try {
				const executions = await this.restApi().listExecutionsFromPod(this.dataModal.instanceName, {ambiente: this.dataModal.ambiente});
                this.listExecutions = executions;

				if (this.execucoesParando.length > 0) {
					this.execucoesParando.forEach(execucaoParando => {
						const execucaoExistente = this.listExecutions.find(execucao => execucao.execucaoId === execucaoParando.execucaoId);
						if (execucaoExistente) {
						execucaoExistente.status = execucaoParando.status;
						}
					});
				}

                await Promise.all(this.listExecutions.map(async (item) => {
                    if (item.status === 'Executando') {
                        this.$set(item, 'tempoDecorrido', this.getTempoDuracao(item));
                        await this.iniciarTemporizador(item);
                    }
                }));

				this.isLoadingPage = false;
				this.isLoadingTable = false;

				
			} catch (error) {
				console.error('error', error);
			}
		},

		getTempoDuracao(item) {
			const currentUTCTime = moment.utc();
			const inicioExecucao = moment.utc(item.inicioExecucao);
			const elapsedTime = currentUTCTime.diff(inicioExecucao);

			const duration = moment.duration(elapsedTime);
			const hours = Math.floor(duration.asHours());
			const minutes = moment.utc(elapsedTime).format("mm");
			const seconds = moment.utc(elapsedTime).format("ss");

			return `${String(hours).padStart(2, '0')}h:${minutes}min:${seconds}s`;
		},
		iniciarTemporizador(item) {
			this.refreshInterval = window.setInterval(() => {
				item.tempoDecorrido = this.getTempoDuracao(item);
			}, 1000);
    },
	},
	async created() {
		await this.initPageExecutionQueueModal();
	},
	mounted() {
		this.intervalTimer = window.setInterval(() => {
			this.initPageExecutionQueueModal();
		}, 5000);
	},
	destroyed () {
		if (this.intervalTimer !== null) {
			clearInterval(this.intervalTimer);
		}

		if (this.refreshInterval !== null) {
			clearInterval(this.refreshInterval);
		}
	},
});
