import MainHeader from '@/components/MainHeader/MainHeader.vue';
import MainSidebar from '@/components/MainSidebar.vue';
import ChangeOrganization from '@/views/ChangeOrganization.vue';
import NodeView from '@/views/NodeView.vue';
import Project from '@/views/Project.vue';
import Variable from '@/views/Variable.vue';
import Profile from '@/views/Profile.vue';
import CreateProfile from '@/views/CreateProfile.vue';
import DataCenterDetailing from '@/views/DataCenterDetailing.vue';
import CreateEditIntegration from '@/views/CreateEditIntegration.vue';
import IntegrationStore from '@/views/IntegrationStore.vue';
import Workspace from '@/views/Workspace.vue';
import IntegrationPanel from '@/views/IntegrationPanel.vue';
import ResourcePanel from '@/views/ResourcePanel.vue';
import EditProfile from '@/views/EditProfile.vue';
import Users from '@/views/Users.vue';
import CreateEditUser from '@/views/CreateEditUser.vue';
import UserDetailing from '@/views/UserDetailing.vue';
import ProfileDetailing from '@/views/ProfileDetailing.vue';
import TheTopNav from '@/components/TheTopNav/TheTopNav.vue';
import AccessOptions from '@/components/AccessOptions.vue';
import ExecutionLog from '@/views/ExecutionLog.vue';
import ExecutionDetail from '@/components/ExecutionDetail.vue';
import AuditLogs from '@/views/AuditLogs.vue';
import AuditLogsDetail from '@/views/AuditLogsDetail.vue';
import ProjectDetailing from '@/views/ProjectDetailing.vue';
import ExecutionsList from '@/components/ExecutionsList.vue';
import CreateVariable from '@/views/CreateVariable.vue';
import Credentials from '@/views/Credentials.vue';
import Equivalences from '@/views/Equivalences.vue';
import EquivalenceDetailing from '@/views/EquivalenceDetailing.vue';
import CreateEquivalence from '@/views/CreateEquivalence.vue';
import CreateEditDataCenter from '@/views/CreateEditDataCenter.vue';
import CreateEditWorkspace from '@/views/CreateEditWorkspace.vue';
import CredentialDetailing from '@/views/CredentialDetailing.vue';
import ExecutionQueue from '@/views/ExecutionQueue.vue';
import ExecutionQueueWebhook from '@/views/ExecutionQueueWebhook.vue';
import KnowMoreIntegration from '@/views/KnowMoreIntegration.vue';
import IntegrationDetailing from '@/views/IntegrationDetailing.vue';
import Integrations from '@/views/Integrations.vue';
import RequestedIntegrations from '@/views/RequestedIntegrations.vue';
import ReceivedIntegrations from '@/views/ReceivedIntegrations.vue'
import ChangeLoading from '@/components/ChangeLoading.vue';
import { previousRoute } from '@/router/index.ts';

import WebStorage from '@/common/WebStorage';
let storedOrganizationId = WebStorage.getItem('tenantId');
let storedWorkspaceId = JSON.parse(WebStorage.getItem('workspace'))?.id;

let previousData = previousRoute;

function prefixRoutes(prefix, routes) {
	return routes.map((route) => {
		route.path = prefix + '' + route.path;
		return route;
	});
}

const redirectPage = storedOrganizationId && storedWorkspaceId ? `/organizacao/${storedOrganizationId}/espacos-de-trabalho/${storedWorkspaceId}/projetos` : storedOrganizationId && !storedWorkspaceId ? `/organizacao/${storedOrganizationId}/espacos-de-trabalho` : '/organizacao'; 

export default [
	...prefixRoutes('/organizacao/:organizationId', [
		{ 
			path: '/espacos-de-trabalho',
			name: 'workspace',
			components: {
				default: Workspace,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Espaços de trabalho",
			}, 
		},
		{
			path: '/espacos-de-trabalho/criacao-espaco-de-trabalho',
			name: 'createWorkspace',
			components: {
				default: CreateEditWorkspace,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Criação de Espaço de Trabalho",
				breadcrumbs: [
					{
						text: "Espaço de Trabalho",
						to: { name: 'workspace' },
					},
					{
						text: "Criação de Espaço de Trabalho",
						href: "#"
					},
				],
			},
		},
		{
			path: '/espacos-de-trabalho/edicao-espaco-de-trabalho/:id',
			name: 'editWorkspace',
			components: {
				default: CreateEditWorkspace,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Edição de Espaço de Trabalho",
				breadcrumbs: [
					{
						text: "Espaço de Trabalho",
						to: { name: 'workspace' },
					},
					{
						text: "Edição de Espaço de Trabalho",
						href: "#"
					},
				],
			},
		},
		{
			path: '/integracoes',
			name: 'integrations',
			components: {
				default: Integrations,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Catálogo de Integração",
				breadcrumbs: [
					{
						text: "Integrações",
						to: { name: 'integrations' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/integracoes/cadastrar-integracao',
			name: 'createIntegration',
			components: {
				default: CreateEditIntegration,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Adição de Integração",
				breadcrumbs: [
					{
						text: "Integrações",
						to: { name: 'integrations' },
					},
					{
						text: "Cadastro de Integração",
						to: { name: 'createIntegration' },
					},
				],
			},
		},
		{
			path: '/carregamento',
			name: 'carregamento',
			// lazy-loaded
			components: {
				default: ChangeLoading,
			},
		},
		{
			path: '/integracoes-recebidas',
			name: 'received-integrations',
			components: {
				default: ReceivedIntegrations,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Catálogo de Integração",
				breadcrumbs: [
					{
						text: "Integrações Recebidas",
						to: { name: 'received-integrations' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/integracoes-solicitadas',
			name: 'requested-integrations',
			components: {
				default: RequestedIntegrations,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Catálogo de Integração",
				breadcrumbs: [
					{
						text: "Integrações Solicitadas",
						to: { name: 'requested-integrations' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/catalogo-de-integracao',
			name: 'integrationStore',
			components: {
				default: IntegrationStore,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Catálogo de Integração",
				breadcrumbs: [
					{
						text: "Catálogo de Integração",
						to: { name: 'integrationStore' },
					},
					{
						text: "",
						href: "#",
					},
				],
			},
		},
		{
			path: '/catalogo-de-integracao/saiba-mais/:id',
			name: 'knowMoreIntegration',
			components: {
				default: KnowMoreIntegration,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Catálogo de Integração de Integração",
				breadcrumbs: [
					{
						text: "Catálogo de Integração",
						to: { name: 'integrationStore' },
					},
					{
						text: "Saiba Mais",
						href: "#",
					},
				],
			},
		},
		{
			path: '/catalogo-de-integracao/detalhamento-da-integracao/:id',
			name: 'integrationDetailing',
			components: {
				default: IntegrationDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhamento da Integração",
				breadcrumbs: [
					{
						text: "Integrações",
						to: { name: 'integrations' },
					},
					{
						text: "Detalhamento da Integração",
						href: "#",
					},
				],
			},
		},
		{
			path: '/catalogo-de-integracao/pre-visualizacao-integracao',
			name: 'integrationPreview',
			components: {
				default: KnowMoreIntegration,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Pré-Visualização Integracao",
				breadcrumbs: [
					{
						text: "Cadastro Integração",
						to: { name: 'createIntegration' },
					},
					{
						text: "Pré-Visualização",
						href: "#",
					},
				],
			},
		},
		{
			path: '/catalogo-de-integracao/editar-integracao/:id',
			name: 'editIntegration',
			components: {
				default: CreateEditIntegration,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Edição de Integração",
				breadcrumbs: [
					{
						text: "Integrações",
						to: { name: 'integrations' },
					},
					{
						text: "Edição de Integração",
						href: "#",
					},
				],
			},
		},
		{
			path: '/painel-de-integracoes',
			name: 'integrationPanel',
			components: {
				default: IntegrationPanel,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Painel de Integrações",
			},
		},
		{
			path: '/monitoramento/painel-de-integracoes',
			name: 'adminIntegrationPanel',
			components: {
				default: IntegrationPanel,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Painel de Integrações",
			},
		},
		// {
		// 	path: '/monitoramento/recursos',
		// 	name: 'resourcePanel',
		// 	components: {
		// 		default: ResourcePanel,
		// 		header: TheTopNav,
		// 		sidebar: MainSidebar,
		// 	},
		// 	meta: {
		// 		title: "Recursos",
		// 		breadcrumbs: [
		// 			{
		// 				text: "Monitoramento",
		// 				to: "#",
		// 			},
		// 			{
		// 				text: "Recursos",
		// 				href: "#",
		// 			},
		// 		],
		// 	},
		// },
		{
			path: '/monitoramento/recursos',
			name: 'resourcePanel',
			components: {
				default: ResourcePanel,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Recursos",
				breadcrumbs: [
					{
						text: "Monitoramento",
						to: "#",
					},
					{
						text: "Recursos",
						href: "#",
					},
				],
			},
		},
		{
			path: '/usuarios',
			name: 'users',
			components: {
				default: Users,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Usuários",
				breadcrumbs: [
					{
						text: "Usuários",
						to: { name: 'users' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/usuarios/cadastro-de-usuario',
			name: 'registerUser',
			components: {
				default: CreateEditUser,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Usuários",
				breadcrumbs: [
					{
						text: "Usuários",
						to: { name: 'users' },
					},
					{
						text: "Cadastro de Usuário",
						href: "#"
					},
				],
			},
		},
		{
			path: '/usuarios/edicao-de-usuario/:id',
			name: 'editUser',
			components: {
				default: CreateEditUser,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Usuários",
				breadcrumbs: [
					{
						text: "Usuários",
						to: { name: 'users' },
					},
					{
						text: "Edição de Usuário",
						href: "#"
					},
				],
			},
		},
		{
			path: '/usuarios/detalhamento-de-usuario/:id',
			name: 'userdetailing',
			components: {
				default: UserDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Usuários",
				breadcrumbs: [
					{
						text: "Usuários",
						to: { name: 'users' },
					},
					{
						text: "Detalhamento do Usuário",
						href: "#"
					},
				],
			},
		},
		{
			path: '/perfis-de-usuario',
			name: 'user-profiles',
			components: {
				default: Profile,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
			title: "Perfis de Usuários",
				breadcrumbs: [
					{
						text: "Perfis de Usuários",
						to: { name: 'user-profiles' },
					},
					{
						text: "",
						href: "#",
					},
				],
			},
		},
		{
			path: '/perfis-de-usuario/cadastro-perfil-de-usuario',
			name: 'createProfile',
			components: {
				default: CreateProfile,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
			title: "Cadastro de Perfis",
				breadcrumbs: [
					{
						text: "Perfis de Usuários",
						to: { name: 'user-profiles' },
					},
					{
						text: "Cadastro de Perfis",
						href: "#",
					},
				],
			},
		},
		{
			path: '/perfis-de-usuario/edicao-de-perfil/:permissionId',
			name: 'editProfile',
			components: {
				default: EditProfile,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Edição de Perfil",
				breadcrumbs: [
					{
						text: "Perfis de Usuários",
						to: { name: 'user-profiles' },
					},
					{
						text: "Edição de Perfi",
						href: "#",
					},
				],
			},
		},
		{
			path: '/perfis-de-usuario/detalhamento-de-perfil/:permissionId',
			name: 'profileDetailing',
			components: {
				default: ProfileDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhe de Perfis",
				breadcrumbs: [
					{
						text: "Perfis de Usuários",
						to: { name: 'user-profiles' },
					},
					{
						text: "Detalhamento do Perfil de Usuário",
						href: "#"
					},
				],
			},
		},
		{
			path: '/fila-de-execucoes',
			name: 'executionQueue',
			components: {
				default: ExecutionQueue,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Fila de Execuções",
				breadcrumbs: [
					{
						text: "Fila de Execuções",
						to: { name: 'executionQueue' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/fila-de-execucoes-webhook',
			name: 'executionQueueWebhook',
			components: {
				default: ExecutionQueueWebhook,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Fila de Execuções Webhook",
				breadcrumbs: [
					{
						text: "Fila de Execuções Webhook",
						to: { name: 'executionQueueWebhook' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
	]),

	...prefixRoutes('/organizacao/:organizationId/espacos-de-trabalho/:workspaceId', [
		{
			path: '/projetos',
			name: 'project',
			// lazy-loaded
			components: {
				default: Project,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Projetos",
			},
		},
		{
			path: '/projetos/detalhamento-de-projeto/:id',
			name: 'projectDetailing',
			components: {
				default: ProjectDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhamento do Projeto",
				breadcrumbs: [
					{
						text: "Projetos",
						to: { name: 'project' },
					},
					{
						text: "Detalhamento do Projeto",
						href: "#",
					},
				],
			},
		},
		{
			path: '/fluxo/:workflowid/versao/:versionid',
			name: 'VersionById',
			components: {
				default: NodeView,
				header: MainHeader,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Versão do Fluxo",
			},
		},
		{
			path: '/novo-fluxo/:projectid',
			name: 'NodeViewNew',
			components: {
				default: NodeView,
				header: MainHeader,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Criação de Fluxo",
			},
		},
		{
			path: '/fluxo/:name/:mode?',
			name: 'NodeViewExisting',
			components: {
				default: NodeView,
				header: MainHeader,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Criação de Fluxo",
			},
		},
		
		{
			path: '/fluxos/templates/:id',
			name: 'workflowTemplate',
			components: {
				default: NodeView,
				header: MainHeader,
				sidebar: MainSidebar,
			},
		},
		{
			path: '/execucoes',
			name: 'executionLog',
			components: {
				default: ExecutionLog,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Execuções",
				breadcrumbs: [
					{
						text: "Execuções",
						to: { name: 'executionLog' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/execucoes/:id',
			name: 'ExecutionById',
			components: {
				default: NodeView,
				header: MainHeader,
				sidebar: MainSidebar,
			},
		},
		{
			path: '/execucoes/detalhamento-de-execucao/:id',
			name: 'executionDetail',
			components: {
				default: ExecutionDetail,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhamento da Execução",
				breadcrumbs: [
					{
						text: "Execuções",
						to: { name: 'executionLog' },
					},
					{
						text: "Detalhamento da Execução",
						href: "#"
					},
				],
			},
		},
		{
			path: '/variaveis',
			name: 'variable',
			components: {
				default: Variable,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Variáveis",
				breadcrumbs: [
					{
						text: "Variáveis",
						to: { name: 'variable' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/variaveis/cadastro-de-variavel',
			name: 'createvariable',
			components: {
				default: CreateVariable,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Cadastro de Variável",
				breadcrumbs: [
					{
						text: "Variáveis",
						to: { name: 'variable' },
					},
					{
						text: "Cadastro de Variável",
						href: "#"
					},
				],
			},
		},
		{
			path: '/equivalencias',
			name: 'equivalences',
			components: {
				default: Equivalences,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Equivalências",
			},
		},
		{
			path: '/equivalencias/cadastro-de-equivalencia/:mode?',
			name: 'createEquivalence',
			components: {
				default: CreateEquivalence,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Cadastro de Equivalências",
				breadcrumbs: [
					{
						text: "Equivalências",
						to: { name: 'equivalences' },
					},
					{
						text: "Cadastro de Equivalências",
						href: "#"
					},
				],
			},
		},
		{
			path: '/equivalencias/edicao-de-equivalencia/:id',
			name: 'editEquivalence',
			components: {
				default: CreateEquivalence,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Edição de Equivalências",
				breadcrumbs: [
					{
						text: "Equivalências",
						to: { name: 'equivalences' },
					},
					{
						text: "Edição de Equivalências",
						href: "#"
					},
				],
			},
		},
		{
			path: '/equivalencias/detalhamento-de-equivalencia/:id',
			name: 'equivalencesDetailing',
			components: {
				default: EquivalenceDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
			title: "Detalhamento da Equivalência",
					breadcrumbs: [
				{
					text: "Equivalências",
					to: { name: 'equivalences' },
				},
				{
					text: "Detalhamento da Equivalência",
					href: "#"
				},
			],
			},
		},
		{
			path: '/fonte-de-dados',
			name: 'dataSource',
			components: {
				default: Equivalences,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Equivalências",
			},
		},
		{
			path: '/fonte-de-dados/cadastro-fonte-de-dados',
			name: 'createDataCenter',
			components: {
				default: CreateEditDataCenter,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Cadastro de Fonte de Dados",
				breadcrumbs: [
					{
						text: "Fonte de Dados",
						to: { name: 'dataSource'},
					},
					{
						text: "Cadastro de Fonte de Dados",
						href: "#",
					},
				],
			},
		},
		{
			path: '/fonte-de-dados/edicao-fonte-de-dados/:id',
			name: 'editDataCenter',
			components: {
				default: CreateEditDataCenter,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Edição Fonte de Dados",
				breadcrumbs: [
					{
						text: "Fonte de Dados",
						to: { name: 'dataSource'},
					},
					{
						text: "Edição de Fonte de Dados",
						href: "#"
					},
				],
			},
		},
		{
			path: '/fonte-de-dados/detalhamento-de-fonte/:id',
			name: 'dataCenterDetailing',
			components: {
				default: DataCenterDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhamento da Fonte",
				breadcrumbs: [
					{
						text: "Fonte de Dados",
						to: { name: 'dataSource'},
					},
					{
						text: "Detalhamento da Fonte de Dados",
						href: "#"
					},
				],
			},
		},
		{
			path: '/credenciais',
			name: 'credentials',
			components: {
				default: Credentials,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
			title: "Credenciais",
				breadcrumbs: [
					{
						text: "Credenciais",
						to: { name: 'credentials' },
					},
					{
						text: "",
						href: "#"
					},
				],
			},
		},
		{
			path: '/credenciais/detalhamento-de-credencial/:id',
			name: 'credentialDetailing',
			components: {
				default: CredentialDetailing,
				header: TheTopNav,
				sidebar: MainSidebar,
			},
			meta: {
				title: "Detalhamento da Credencial",
				breadcrumbs: [
					{
						text: "Credenciais",
						to: { name: 'credentials' },
					},
					{
						text: "Detalhamento da Credencial",
						href: "#"
					},
				],
			},
		},
	]),
	
	{
		path: '/organizacao',
		name: 'selecaoDeOrganizacao',
		// lazy-loaded
		components: {
			default: ChangeOrganization,
		},
	},
	{
		path: '/mudanca-de-organizacao',
		name: 'mudancaDeOrganizacao',
		components: {
			default: ChangeOrganization,
			header: TheTopNav,
			sidebar: MainSidebar,
		},
		meta: {
			title: "Mudança de Organização",
			breadcrumbs: [
				{
					text: "Projetos",
					to: { name: 'project' },
				},
				{
					text: "Mudança de Organização",
					href: "#"
				},
			],
		},
	},
	// {
	//   path: '/logexecucao',
	//   name: 'logexecucao',
	//   // lazy-loaded
	//   component: () => import(/* webpackChunkName: "user" */ '@/views/LogExecucao.vue'),
	// },

	
	// {
	// 	path: '/auditlog',
	// 	name: 'auditLogs',
	// 	components: {
	// 		default: AuditLogs,
	// 		header: TheTopNav,
	// 		sidebar: MainSidebar,
	// 	},
	// 	meta: {
    //   title: "Auditoria",
	// 		breadcrumbs: [
    //     {
    //       text: "Auditoria",
    //       to: { name: 'auditLogs' },
    //     },
	// 			{
    //       text: "",
	// 				href: "#"
    //     },
    //   ],
    // },
	// },
	// {
	// 	path: '/auditlog/auditlogdetail/:id',
	// 	name: 'auditlogdetail',
	// 	components: {
	// 		default: AuditLogsDetail,
	// 		header: TheTopNav,
	// 		sidebar: MainSidebar,
	// 	},
	// 	meta: {
    //   title: "Detalhamento da Auditoria",
	// 		breadcrumbs: [
    //     {
    //       text: "Auditoria",
    //       to: { name: 'auditLogs' },
    //     },
	// 			{
    //       text: "Detalhamento da Auditoria",
	// 				href: "#"
    //     },
    //   ],
    // },
	// },
	
	// {
	// 	path: '/executionlist',
	// 	name: 'executionsList',
	// 	components: {
	// 		default: ExecutionsList,
	// 		header: TheTopNav,
	// 		sidebar: MainSidebar,
	// 	},
	// },
	// {
	// 	path: '/accessoptions',
	// 	name: 'accessOptions',
	// 	components: {
	// 		default: AccessOptions,
	// 		header: TheTopNav,
	// 		sidebar: MainSidebar,
	// 	},
	// },

	
	// {
	// 	path: '/logexecucao',
	// 	name: 'logExecucao',
	// 	components: {
	// 		default: LogExecucao,
	// 	},
	// },
	// {
	// 	path: '/detailsexecution',
	// 	name: 'detailsExecution',
	// 	components: {
	// 		default: DetailsExecution,
	// 	},
	// },
	
	
	
	
	
	// {
	// 	path: '/organizacao/:organizationId/espacos-de-trabalho',
	// 	name: 'workspace',
	// 	components: {
	// 		default: Workspace,
	// 		header: TheTopNav,
	// 		sidebar: MainSidebar,
	// 	},
	// 	meta: {
	// 	title: "Espaços de trabalho",
	// 	},
	// },
	
	{
		path: '*',
		redirect: redirectPage,
	}
];
