import { hasPermissionByDescription, verifyPermissionDescription, verifyAdminUser } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const resourcePermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			dataResource: '',
            isAdmin: '',
			permission: '',
		};
	},

	async created() {
		await this.validationAcessResource();
	},

	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
        stopExecutionResourcePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.stopExecutionResource, this.permission?.access);
		},
        reloadPodPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.reloadPod, this.permission?.access);
		},
        accessResourcePanelPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageResource, this.permission?.access);
		},
	},

	methods: {
		async validationAcessResource() {
			this.isAdmin = await verifyAdminUser();
			this.permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.dataResource = verifyPermissionDescription(this.PERMISSION_IDS?.manageResource, this.permission?.access);

			if (!this.dataResource) {
                this.$router.push({
					name: 'workspace',
				});

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			}
		},
        canReloadPod() {
			return this.reloadPodPermission && this.isAdmin;
        },
        canStopExecutionResourcePanel() {
			return this.stopExecutionResourcePermission && this.isAdmin;
        },

		canAccessResourcePanel() {
			return this.accessResourcePanelPermission;
		},
	},
});
