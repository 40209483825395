
import { SHARE_MODAL_KEY } from '../constants';
import Vue from 'vue';
import debounce from 'lodash.debounce';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import { removeItemFromArrayByValue } from '@/components/helpers';
import {
	IUserShare,
} from '../Interface';


import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import InfoAliare from '@/components/InfoAliare.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'ShareModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
		ScrollInfinite,
		InfoAliare,
		AlertNotificationModal,
	},
	props: {
		activeId: {
			type: String | Array,
			required: true,
		},
		mode: {
			type: String,
		},
		owner: {
			type: Object,
		},
		viewMode: {
			type: Boolean,
		},
	},
	data() {
		return {
			modalBus: new Vue(),
			SHARE_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			newShare: false,
			generalPermission: 'canSee',
			message: '',
			oldTypeShare: '',
			searchInput: '',
			loadingAll: false,
			loadingSection: false,
			displayMode: this.$locale.baseText('shareModal.see'),
			usersTenant: [],
			activeSecondSection: 'all',
			profilesTenant: [],
			usersSelected: [] as IUserShare,
			profilesSelected: [] as IUserShare,
			oldShareUsers: '',
			isLoadingAction: false,
			generalPermissionActive: false,
			showAlert: {
				active: false,
				mode: '',
			},
		};
	},
	computed: {
		profilesFiltered() {
			return this.profilesTenant.filter(user => {
				return user.name?.toLowerCase().includes(this.searchInput.toLowerCase());
			});
		},
		usersFiltered() {
			return this.usersTenant.filter(user => {
				return user.nome?.toLowerCase().includes(this.searchInput.toLowerCase());
			});
		},
		dynamicTitleFirstSection()  {
			return (!this.viewMode ? 'Escolha o Tipo de Compartilhamento' : 'Tipo de Compartilhamento') + ' *';
		},
		dynamicSuccessMessage() {
			if (this.multipleShare) {
				return `(${ this.activeId.length }) ${this.dynamicNameObject} foram compartilhadas em lote com sucesso.`;
			} else if (this.mode == 'Projeto') {
				return `O Projeto "${this.owner.nome}" e suas Variáveis foram Compartilhados com sucesso!`;
			} else if (this.mode == 'Equivalencia') {
				return `A Equivalência "${this.owner.nome}" foi compartilhada com sucesso!`;
			} else if (this.mode == 'Workflow') {
				return `o Fluxo "${this.owner.nome}" do Projeto "${this.owner.nomeProjeto}" foi compartilhado com sucesso!`;
			} else {
				return `A Credencial "${this.owner.nome}" foi compartilhada com sucesso!`;
			}
		},

		dynamicNameObject() {
			if (this.mode == 'Projeto') {
				return `${ this.multipleShare ? 'Projetos' : 'Projeto'}`;
			} else if (this.mode == 'Equivalencia') {
				return `${ this.multipleShare ? 'Equivalencias' : 'Equivalencia'}`;
			} else if (this.mode == 'Workflow') {
				return `${ this.multipleShare ? 'Fluxos' : 'Fluxo'}`;
			} else if (this.mode == 'Credencial') {
				return `${ this.multipleShare ? 'Credenciais' : 'Credencial'}`;
			}
		},
		dynamicNotFoundShareViewMode() {
			if (this.mode == 'Projeto') {
				return `Não existe nenhum compartilhamento ativo para este Projeto. Você não possui permissão para editar.`;
			} else if (this.mode == 'Equivalencia') {
				return `Não existe nenhum compartilhamento ativo para esta Equivalência. Você não possui permissão para editar.`;
			} else if (this.mode == 'Workflow') {
				return `Não existe nenhum compartilhamento ativo para este Fluxo. Você não possui permissão para editar.`;
			} else {
				return `Não existe nenhum compartilhamento ativo para esta Credencial. Você não possui permissão para editar.`;
			}
		},
		viewModeInfo() {
			return "Você está vendo o compartilhamento em <b>modo de visualização</b>. Você pode apenas <b>visualizar</b> com quais usuários está sendo compartilhado"
		},
		dynamicShareInfoHeader() {
			if (this.newShare) {
				if (this.mode == 'Projeto') {
					return "Este <b>Projeto</b> e suas <b>Variáveis</b> ainda <b>não foram compartilhados</b>. Você pode definir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> ao projeto. Entretanto, as Equivalências, Credenciais e Variáveis não poderão ser editadas ou reaproveitadas em outros projetos, pois esses itens precisarão ser compartilhados individualmente em suas respectivas telas.";
				} else if (this.mode == 'Equivalencia') {
					return `Esta Equivalência ainda <b>não foi compartilhada</b>. Você pode definir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> a esta equivalência.`;
				} else if (this.mode == 'Workflow') {
					return "Este <b>Fluxo</b> e suas <b>Variáveis</b> ainda <b>não foram compartilhados</b>. Você pode definir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> ao fluxo. Entretanto, as Equivalências, Credenciais e Fluxos Anexados não poderão ser editadas ou reaproveitadas em outros fluxos, pois esses itens precisarão ser compartilhados individualmente em suas respectivas telas.";
				} else {
					return `Esta Credencial ainda <b>não foi compartilhada</b>. Você pode definir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> a esta credencial.`;
				}
			} else {
				if (this.mode == 'Projeto') {
					return "Este <b>Projeto</b> e suas Variáveis já estão sendo compartilhados. Você pode redefinir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> ao projeto. Entretanto, as Equivalências, Variáveis e Credenciais não poderão ser editadas ou reaproveitadas em outros projetos, pois esses itens precisarão ser compartilhados individualmente em suas respectivas telas.";
				} else if (this.mode == 'Equivalencia') {
					return `Esta <b>Equivalência</b> já está sendo compartilhada. Você pode redefinir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> a esta equivalência.`;
				} else if (this.mode == 'Workflow') {
					return "Este <b>Fluxo</b> e suas <b>Variáveis</b> já estão sendo <b>compartilhados</b>. Você pode redefinir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> ao fluxo. Entretanto, as Equivalências, Credenciais e Fluxos Anexados não poderão ser editadas ou reaproveitadas em outros fluxos, pois esses itens precisarão ser compartilhados individualmente em suas respectivas telas.";
				} else {
					return `Esta <b>Credencial</b> já está sendo <b>compartilhada</b>. Você pode redefinir quais usuários terão acessos de <b>visualização</b> e <b>edição</b> a esta credencial.`;
				}
			}

		},
		dynamicTitleModal() {
			if (this.mode == 'Projeto') {
				return `${ this.viewMode ? 'Visualizar' : 'Gerenciar'} Compartilhamento do Projeto com os Usuários`;
			} else if (this.mode == 'Equivalencia') {
				return `${ this.viewMode ? 'Visualizar' : 'Gerenciar'} Compartilhamento da Equivalência com os Usuários`;
			} else if (this.mode == 'Workflow') {
				return `${ this.viewMode ? 'Visualizar' : 'Gerenciar'} Compartilhamento do Fluxo com os Usuários`;
			} else {
				return `${ this.viewMode ? 'Visualizar' : 'Gerenciar'} Compartilhamento da Credencial com os Usuários`;
			}
		},
		dynamicAllMessage() {
			if (this.mode == 'Workflow') {
				return 'Todos os usuários já cadastrados e os que vierem a ser cadastrados para a organização, ' +  '<b>compartilharão este Fluxo e suas Váriaveis.<b/>';
			} else if (this.mode == 'Equivalencia') {
				return 'Todos os usuários já cadastrados e os que vierem a ser cadastrados para a organização, ' +  '<b>compartilharão esta Equivalência.<b/>';
			} else if (this.mode == 'Credencial') {
				return 'Todos os usuários já cadastrados e os que vierem a ser cadastrados para a organização, ' +  '<b>compartilharão esta Credencial.<b/>';
			} else {
				return 'Todos os usuários já cadastrados e os que vierem a ser cadastrados para a organização, ' +  '<b>compartilharão este Projeto e suas variáveis.<b/>';
			}
		},
		dynamicTitleClearShareModal() {
			if (this.mode == 'Projeto') {
				return `Limpar Compartilhamento do Projeto ${ this.onlyOneItem ? '' : 'em Lote'}`;
			} else if (this.mode == 'Equivalencia') {
				return `Limpar Compartilhamento da Equivalência ${ this.onlyOneItem ? '' : 'em Lote'}`;
			} else if (this.mode == 'Workflow') {
				return `Limpar Compartilhamento do Fluxo ${ this.onlyOneItem ? '' : 'em Lote'}`;
			} else {
				return `Limpar Compartilhamento da Credencial ${ this.onlyOneItem ? '' : 'em Lote'}`;
			}
		},
		dynamicClearShareMessageModal() {
			if (this.mode == 'Workflow') {
				return 'Ao limpar o compartilhamento deste <b>Fluxo</b> e suas <b>Variáveis</b>, todos os usuários perderão suas permissões aos mesmos. O que deseja fazer?';
			} else if (this.mode == 'Equivalencia') {
				return 'Ao limpar o compartilhamento desta <b>Equivalência</b>, todos os usuários perderão suas permissões ao mesmo. O que deseja fazer?';
			} else if (this.mode == 'Credencial') {
				return 'Ao limpar o compartilhamento desta <b>Credencial</b>, todos os usuários perderão suas permissões ao mesmo. O que deseja fazer?';
			} else {
				return 'Ao limpar o compartilhamento deste <b>Projeto</b> e suas <b>Variáveis</b>, todos os usuários perderão suas permissões aos mesmos. O que deseja fazer?';
			}
		},
		dynamicTitleHeader() {
			if (this.activeSecondSection == 'all') {
				return (!this.viewMode ? 'Defina a Permissão dos Usuários' : 'Permissão dos Usuários') + ' *';

			} else if (this.activeSecondSection == 'profiles') {
				return (!this.viewMode ? 'Selecione os Perfis de Usuários' : 'Permissão dos Perfis de Usuários')  + ' *';

			} else {
				return (!this.viewMode ? 'Selecione os Usuários' : 'Permissão dos Usuários')  + ' *';
			}
		},
		activeButton() {
			if (this.loadingAll) {
				return false;
			}
			if (this.activeSecondSection == 'all') {
				return true;
			} else if (this.activeSecondSection == 'specific-user' && this.usersSelected.length > 0) {
				return true;
			} else if (this.activeSecondSection == 'profiles' && this.profilesSelected.length > 0) {
				return true;
			} else {
				return false;
			}
		},
		allUsersSelected() {
			return this.usersAbleToSelect == this.usersSelected.length;
		},
		partialUsersSelected() {
			return this.usersAbleToSelect != this.usersSelected.length && this.usersSelected.length > 0;
		},
		allProfilesSelected() {
			return this.profilesAbleToSelect == this.profilesSelected.length;
		},
		partialProfilesSelected() {
			return this.profilesAbleToSelect != this.profilesSelected.length && this.profilesSelected.length > 0;
		},
		usersAbleToSelect() {
			if (this.multipleShare) {
				return this.usersTenant.filter(user => !user.adminUser).length;
			} else {
				return this.usersTenant.filter(user => user.id !== this.owner.id && !user.adminUser).length;
			}
		},
		profilesAbleToSelect() {
			if (this.multipleShare) {
				return this.profilesTenant.filter(profile => !profile.adminUser).length;
			} else {
				return this.profilesTenant.filter(profile => profile.id !== this.owner.id && !profile.adminUser).length;
			}
		},
		hasUserEditPermission() {
			return !!this.usersSelected.find(user => user.permission == 'canEdit');
		},
		hasProfileEditPermission() {
			return !!this.profilesSelected.find(profile => profile.permission == 'canEdit');
		},
		hasProfileViewPermission() {
			return !!this.profilesSelected.find(profile => profile.permission == 'canSee');
		},
		hasUserViewPermission() {
			return !!this.usersSelected.find(user => user.permission == 'canSee');
		},
		typeShare() {
			return this.activeSecondSection == 'all' ? 'Workspace' : this.activeSecondSection == 'profiles' ? 'Perfil' : 'Usuario';
		},
		multipleShare() {
			return this.activeId.length > 1;
		},
		onlyOneItem() {
			return this.activeId.length == 1;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
		keepText() {
			if (this.showAlert.mode == 'deleteShare') {
				return `Manter Compartilhamento`;
			}
		},
	},
	mounted () {
	},
	async created() {
		this.loadingAll = true;
		await this.restApi().getUsersInWorkspace(this.workspace.id).then((response) => {
			this.usersTenant.push(...response);
		});

		const returnReducedProfile = true;
		await this.getProfilesByTenantId({returnReducedProfile}).then((response) => {
			this.profilesTenant.push(...response);
		});

		if (!this.multipleShare) {
			await this.getShareData();
		}


		if (this.newShare && this.viewMode) {
			this.modalBus.$emit('close');

			this.$store.commit('activeAlert', {
				message: this.dynamicNotFoundShareViewMode,
				status: 'error',
			});
		};
		this.loadingAll = false;
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'getProfilesByTenantId']),

		openAlertDeleteShare() {
			this.showAlert.active = true;
			this.showAlert.mode = 'deleteShare';
		},
		closeAlert() {
			this.showAlert.mode = '';
			this.showAlert.active = false;
		},
		verifyPermission(user) {
			if (this.verifyUserSelected(user) || this.verifyProfileSelected(user) && !this.generalPermissionActive) {
				return user.permission == 'canEdit' ? true : false;
			} else {
				return this.generalPermission == 'canSee' ? false : true;
			}
		},
		verifyUserAdmin(user) {
			return user.adminUser;
		},
		verifyUserOwner(user) {
			return this.owner.id === user.id;
		},
		selectAllProfiles() {
			if (this.partialProfilesSelected || this.allProfilesSelected) {
				this.profilesSelected = [];
			} else {
				this.profilesTenant.forEach((profile) =>{
						this.addProfileToPermission(profile);
				});
			}
		},
		selectAllUsers() {
			if (this.partialUsersSelected || this.allUsersSelected) {
				this.usersSelected = [];
			} else {
				this.usersTenant.forEach((user) =>{
					if (this.multipleShare || !this.verifyUserOwner(user) && !this.verifyUserAdmin(user)) {
						this.addUserToPermission(user);
					}
				});
			}
		},
		async getShareData() {
			try {
				await this.restApi().getShareData(this.workspace.id, this.activeId, {tipoObjeto: this.mode}).then((response) => {
					this.usersSelected = [];
					if (!response.length) {
						this.newShare = true;
					} else {
						response.forEach(share => {
							if (share.permissao == 'Visualizar') {
								if (share?.usuariosCompartilhados?.length) {
									this.activeSecondSection = 'specific-user';
									const oldShareUsersView = share.usuariosCompartilhados;

									oldShareUsersView.forEach((oldUser) => {
										const userCompatible = this.usersTenant.find(user => user.id == oldUser);
										if (!!userCompatible) {
											userCompatible.permission = 'canSee';
											this.usersSelected.push(userCompatible);
										}
									});
								}
								else if (share?.perfisCompartilhados?.length) {
									this.activeSecondSection = 'profiles';
									const oldShareProfilesView = share.perfisCompartilhados;

									oldShareProfilesView.forEach((oldProfile) => {
										if (oldProfile == null) return;

										const profileCompatible = this.profilesTenant.find(profile => profile.id == oldProfile);
										if (!!profileCompatible) {
											profileCompatible.permission = 'canSee';
											this.profilesSelected.push(profileCompatible);
										}
									});
								} else {
									this.activeSecondSection = 'all';
									this.generalPermission = 'canSee';
								}
							}
							if (share.permissao == 'Editar') {
								if (share?.usuariosCompartilhados?.length) {
									this.activeSecondSection = 'specific-user';
									const oldShareUsersEdit = share.usuariosCompartilhados;

									oldShareUsersEdit.forEach((oldUser) => {
										const userCompatible = this.usersTenant.find(user => user.id == oldUser);
										if (!!userCompatible) {
											userCompatible.permission = 'canEdit';
											this.usersSelected.push(userCompatible);
										}
									});
								} else if (share?.perfisCompartilhados?.length) {
									this.activeSecondSection = 'profiles';
									const oldShareProfilesEdit = share.perfisCompartilhados;

									oldShareProfilesEdit.forEach((oldProfile) => {
										if (oldProfile == null) return;
										const profileCompatible = this.profilesTenant.find(profile => profile.id == oldProfile);
										if (!!profileCompatible) {
											profileCompatible.permission = 'canEdit';
											this.profilesSelected.push(profileCompatible);
										}
									});
								} else {
									this.activeSecondSection = 'all';
									this.generalPermission = 'canEdit';
								}

							}
						});
					}
				});
			} catch (e) {
				console.error(e);
			}
		},

		async shareObject() {
			if (this.typeShare == 'Workspace') {
				await this.shareObjectAll();
			} else {
				await	this.shareObjectSpecific();
			}

			this.modalBus.$emit('close');
			this.$root.$emit('reloadList');
		},

		async shareObjectSpecific() {
			if (this.activeSecondSection == 'specific-user') {
				let payloadUser = [];
				this.usersSelected.forEach(user => {
					payloadUser.push({escopoId: user.id, permissao: user.permission == 'canSee' ? 'Visualizar' : 'Editar'});
				});

				await Promise.all(
					[
						this.restApi().createNewShareObject(this.workspace.id, {
							"objetoId": this.activeId,
							"tipoObjeto": this.mode,
							"escopo": this.typeShare,
							"mensagem": this.message,
							"listaEscopoIdsEPermissoes": [
								...payloadUser,
							],
						}),
					],
				).then((response) =>{
					this.$root.$emit('closeSelectItemsComponent');

					this.$store.commit('activeAlert', {
						message: this.dynamicSuccessMessage,
					});
					this.$root.$emit('iconChange', { objetoId: this.activeId, tipoObjeto: this.mode });
				}).catch((e) => {
					this.$store.commit('activeAlert', {
						message: 'Houve um problema ao tentar compartilhar a Equivalência. Tente Novamente',
						status: 'error',
					});
					console.error(e);
				});
			} else if (this.activeSecondSection == 'profiles') {
				let payloadProfile = [];
				this.profilesSelected.forEach(profile => {
					payloadProfile.push({escopoId: profile.id, permissao: profile.permission == 'canSee' ? 'Visualizar' : 'Editar'});
				});

				await Promise.all(
					[
						this.restApi().createNewShareObject(this.workspace.id, {
							"objetoId": this.activeId,
							"tipoObjeto": this.mode,
							"escopo": this.typeShare,
							"mensagem": this.message,
							"listaEscopoIdsEPermissoes": [
								...payloadProfile,
							],
						}),
					],
				).then((response) =>{
					this.$root.$emit('closeSelectItemsComponent');
					this.$store.commit('activeAlert', {
						message: this.dynamicSuccessMessage,
					});
					this.$root.$emit('iconChange', { objetoId: this.activeId, tipoObjeto: this.mode });

				}).catch((e) => {
					this.$root.$emit('shareFail');
					this.$store.commit('activeAlert', {
						message: 'Houve um problema ao tentar compartilhar a Equivalência. Tente Novamente',
						status: 'error',
					});

					console.error(e);
				});
			};

		},
		async shareObjectAll() {

			await this.restApi().createNewShareObject( this.workspace.id,
				{
					"objetoId": this.activeId,
					"tipoObjeto": this.mode,
					"escopo": this.typeShare,
					"permissao": this.generalPermission == 'canSee' ? 'Visualizar' : 'Editar',
					"mensagem": this.message,
				}).then((response) => {
					this.$root.$emit('closeSelectItemsComponent');
					this.$store.commit('activeAlert', {
						message: this.dynamicSuccessMessage,
					});
					this.$root.$emit('iconChange', { objetoId: this.activeId, tipoObjeto: this.mode });
				}).catch((e) => {
					this.$root.$emit('shareFail');
					this.$store.commit('activeAlert', {
						message: 'Houve um problema ao tentar compartilhar a Equivalência. Tente Novamente',
						status: 'error',
					});

					console.error(e);
				});
		},

		async deleteShareObject(showMessage) {
			this.isLoadingAction = true;
			try {
				await this.restApi().deleteShare(this.workspace.id, { objetoId: this.activeId, tipoObjeto: this.mode});
				if (showMessage) {
					this.$store.commit('activeAlert', {
						message: "Compartilhamento limpado com sucesso.",
					});

					this.showAlert.active = false;

					this.$root.$emit('iconChange', { objetoId: this.activeId, tipoObjeto: this.mode, deleteShare: true });
					this.$root.$emit('closeSelectItemsComponent');
					this.$root.$emit('reloadList');

					this.modalBus.$emit('close');
				};
			} catch (e) {
				console.error(e);
			}
			this.isLoadingAction = false;

		},
		changeAllPermission(listUserSelected, listUser, permission) {
			this.generalPermissionActive = true;

			if (this.generalPermission == 'canSee') {
				this.generalPermission = permission ?? 'canEdit';

				listUserSelected.forEach(user => {
					user.permission = permission ?? 'canEdit';
				});
				listUser.forEach(user => {
					user.permission = permission ?? 'canEdit';
				});
			} else {
				this.generalPermission = permission ?? 'canSee';
				listUserSelected.forEach(user => {
					user.permission = permission ?? 'canSee';
				});
				listUser.forEach(user => {
					user.permission = permission ?? 'canSee';
				});
			}

			// Reload computed property
			listUserSelected.push('');
			listUserSelected.pop();

		},
		togglePermission(verifyUser, listUserSelected, listUser){
			this.generalPermissionActive = false;
			let userSelected = listUserSelected.findIndex((user) => user.id === verifyUser.id );
			let userSelected2 = listUser.findIndex((user) => user.id === verifyUser.id );


			if(listUserSelected[userSelected].permission == 'canSee') {
				delete listUserSelected[userSelected].permission;

				this.$set(listUserSelected[userSelected], 'permission', 'canEdit');
				this.$set(listUser[userSelected2], 'permission', 'canEdit');
			} else {
				delete listUserSelected[userSelected].permission;

				this.$set(listUserSelected[userSelected], 'permission', 'canSee');
				this.$set(listUser[userSelected2], 'permission', 'canSee');
			}

			if (this.hasUserViewPermission && !this.hasUserEditPermission || this.hasProfileViewPermission && !this.hasProfileEditPermission) {
				this.generalPermission = 'canSee';
			} else if (!this.hasUserViewPermission && this.hasUserEditPermission || !this.hasProfileViewPermission && this.hasProfileEditPermission) {
				this.generalPermission = 'canEdit';
			}
		},

		verifyUserSelected(verifyUser) {
			return this.usersSelected.find(user => user.id === verifyUser.id);
		},

		verifyProfileSelected(verifyProfile) {
			return this.profilesSelected.find(profile => profile.id === verifyProfile.id);
		},
		addUserToPermission(user) {
			if (this.verifyUserSelected(user)) {
				removeItemFromArrayByValue(this.usersSelected, user);
			} else {
				user.permission = this.generalPermission;
				this.usersSelected.push(user);
			}
		},
		addProfileToPermission(profile) {
			if (this.verifyProfileSelected(profile)) {
				removeItemFromArrayByValue(this.profilesSelected, profile);
			} else {
				profile.permission = this.generalPermission;
				this.profilesSelected.push(profile);
			}
		},
		debounceInput: _.debounce(function (e) {
			this.searchInput = e.target.value;
		}, 500),
		changeSecondSection(type) {
			if (type == this.activeSecondSection) return;

			this.loadingSection = true;
			this.generalPermission = 'canSee';
			this.activeSecondSection = type;
			setTimeout(() => {
				this.loadingSection = false;
			}, 300);
		},
		changePermission(permission) {
			this.generalPermission = permission;
		},
	},
});
