import { hasPermissionByDescription, verifyPermissionDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const accessIntegrationPanelPermission = Vue.extend({
	data () {
		return {
			PERMISSION_IDS,
			dataCredential: '',
			dataIntegrationPanel: '',
			permission: '',
			isAdmin: '',
		};
	},

	async created() {
		await this.validationAccessIntegrationPanel();
	},

	computed: {
		accessIntegrationPanelPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.accessIntegrationPanel, this.permission?.access);
		},

		showAdminPanelIntegrationPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.adminIntegrationPanel, this.permission?.access);
		},

		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		async validationAccessIntegrationPanel() {
			this.permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.dataIntegrationPanel = verifyPermissionDescription(this.PERMISSION_IDS?.accessIntegrationPanel, this.permission?.access);

			if (!this.dataIntegrationPanel) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			}
		},

		canViewIntegrationPanel() {
			return this.accessIntegrationPanelPermission;
		},

		canViewAdminPanelIntegration() {
			return this.showAdminPanelIntegrationPermission;
		},

	},
});
