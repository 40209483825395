
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import BackgroundView from '@/components/BackgroundView.vue';
import Vue from 'vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';

import Table from '@/components/Table.vue';
import ShareUserFilter from '@/components/ShareUserFilter.vue';
import { ICredentialsResponse } from '@/Interface';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import DatePicker from '@/components/DatePicker2.vue';
import { credentialPermission } from '@/components/mixins/permissions/credentials';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { removeItemFromArrayByValue } from '@/components/helpers';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import moment from 'moment';
import { previousRoute } from '../router/index.ts';

import WebStorage from '@/common/WebStorage';


import {
	CREDENTIAL_SELECT_MODAL_KEY,
} from '@/constants';

export default mixins(
	nodeHelpers,
	showMessage,
	credentialPermission,
	genericHelpers,
).extend({
	components: {
		BackgroundView,
		InteractionMenu,
		ShareUserFilter,
		Table,
		RoundPhotoFrame,
		SelectFilter,
		DatePicker,
		FilterIcon,
		ModalButtonCancel,
		FilterBar,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'Credentials',
	data() {
		return {
			clearFilteringActive: false,
			filtersActive: {},
			queryDate: {
				inicio: this.$route.query?.DataHoraUltimaAtualizacaoInicio || '',
				fim: this.$route.query?.DataHoraUltimaAtualizacaoFim || '',
			},
			queryUser: typeof this.$route.query?.usuariosIdsCompartilhado == 'string' ? [this.$route.query?.usuariosIdsCompartilhado] : this.$route.query?.usuariosIdsCompartilhado || [],
			value: '',
			previousRoute: previousRoute,
			interactionMenu__link: '',
			numPicture: 3,
			componentKey: 0,
			maxheight: '60vh',
			interactionMenu__titleHeader: 'credentials.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder:
				'credentials.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__headerPlaceHolderSearch:
				'credentials.interactionMenu__headerPlaceHolderSearch',
			interactionMenu__buttonToCleanFilter: 'credentials.interactionMenu__buttonToCleanFilter',

			tableTitle__1: 'credentials.tableTitle__credentials',
			tableTitle__2: 'credentials.tableTitle__applicationsOrServices',
			tableTitle__3: 'credentials.tableTitle__updateDates',
			tableTitle__4: 'credentials.tableTitle__sharing',
			tableTitle__5: 'credentials.tableTitle__actions',

			credentials__tableActionsButtonLeftDropdown:
				'credentials.credentials__tableActionsButtonLeftDropdown',
			credentials__tableActionsButtonLeftDropdownDelete:
				'credentials.credentials__tableActionsButtonLeftDropdownDelete',
			credentials__modalCreateTitle: 'credentials.credentials__modalCreateTitle',
			interactionMenu__buttonToRegisteredCredentials:
				'credentials.interactionMenu__buttonToRegisteredCredentials',
			credentials__modalCreateAlert: 'credentials.credentials__modalCreateAlert',
			scrollInfinite: true,
			dataTable: [],
			filtering: false,
			numCells: '',
			page: 1,
			pageSize: 10,
			isLoadingTable: false,
			isLoadingPage: false,
			showAlert: false,
			arrayCredentialsSelected: [],

			applyedFilters: [],
			organizationInfo: '',
			lastFilterDate: '',
			excludeData: [],
			renderComponent: true,
			isLoadingAction: false,
		};
	},
	computed: {
		allCredentialsSelected() {
			return this.credentialsAbleToSelect == this.arrayCredentialsSelected.length && this.arrayCredentialsSelected.length > 0;
		},
		credentialsAbleToSelect() {
			return this.dataTable.filter(credential => credential.permissao == 'Editar').length;
		},
		partialCredentialsSelected() {
			return this.credentialsAbleToSelect != this.arrayCredentialsSelected.length && this.arrayCredentialsSelected.length > 0;
		},
		shareUserText() {
      if (!this.queryUser.length) {
        return 'Compartilhando';
      }
    },
		alertNotificationTitle() {
			return 'Excluir Credencial';
		},
		alertNotificationDescription() {
			return `Ao excluir a Credencial <b>${ this.excludeData.nome }</b> você não poderá recuperá-las. O que você deseja fazer?`;
		},
		intervalDateText() {
			if (this.queryDate.inicio == '' && this.queryDate.fim == '') {
					return 'Datas e Horários';
			}

			const inicioFormatado = this.queryDate.inicio ? this.formatDate(this.queryDate.inicio) : 'Data Inicial';
			const fimFormatado = this.queryDate.fim ? this.formatDate(this.queryDate.fim) : 'Data Atual';

			return `De: ${inicioFormatado}\nAté: ${fimFormatado}`;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
		textEmpty(){
			return this.filtering ? 'Não existe nenhum resultado com o(s) filtro(s) aplicado(s)! ' : this.$locale.baseText('credentialsList.emptyList')
		},
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadTable();
		});
		this.$root.$on('cancelSwitchMode', () => {
			this.dataTable.forEach((row) => {
				row.selectedCredential = false;
			});
			this.arrayCredentialsSelected = [];
			this.forceRerender();
		});

		this.updateNumCells();
		window.addEventListener('resize', this.updateNumCells);

		const loadPromises = [
			this.loadNodeTypes(),
		];

		try {
			await Promise.all([
				await this.$store.dispatch('credentials/fetchCredentialTypes'),
				await this.$store.dispatch('credentials/fetchAllCredentials'),
				(loadPromises),
			]);
		}	catch (e) {
			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('credentialsList.errorOnLoadingCredentials'),
				status: 'error',
			});
		}

	},
	async created() {
		this.isLoadingPage = true;
		this.organizationInfo = JSON.parse(WebStorage.getItem('organizationData'));
		await this.verifyPermissionUserAndInitiate();
		this.isLoadingPage = false;
	},
	methods: {
		...mapActions('auth', ['revokeTokens']),

		emitSelectAll() {
			this.isLoadingTable = true;
			if (this.allCredentialsSelected) {
				this.dataTable.forEach((form) => {
					form.selectedCredential = false;
				});
				this.arrayCredentialsSelected = [];
				this.isLoadingTable = false;
				this.$root.$emit('deselectAll');
			} else {
				this.getCredentialsList({sizeInfinite: true});
			}
		},
		async forceRerender() {
			this.renderComponent = false;

			await Vue.nextTick();

			this.renderComponent = true;
    },
		toggleSelectedCredential(row, emitSwitch) {
			if (row.permissao != 'Editar') return;

			this.$root.$emit('addOrRemoveItemSelected', JSON.parse(JSON.stringify({
				...row,
				canShare: this.canShareCredential(row),
			})));

			row.selectedCredential = !row?.selectedCredential;

			if (row.selectedCredential) {
				this.arrayCredentialsSelected.push(row);
			} else {
				let index = this.arrayCredentialsSelected.map(items => {
					return items.id;
				}).indexOf(row.id);

				this.arrayCredentialsSelected.splice(index, 1);
			}
			if (emitSwitch) {
				this.$root.$emit('switchSelectMode', {active: true, object: 'Credencial'});
			}
			this.forceRerender();
		},

		limitedUsersShareFilter(listUsers) {
			return listUsers.slice(0, this.numPicture);
		},
		async verifyPermissionUserAndInitiate() {
			await this.validationAccessCredentials().then(async () => {
				if (this.showCredentialsPermission) {
					await this.initPageCredentials();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},

		limitedUsersShare(listUsers){
			return listUsers.slice(0,this.numCells);
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},

		async filterByUser(dataFilter) {
			const userIds = dataFilter.map(item => item.id);

			this.queryUser = dataFilter;
			this.addOrRemoveFilter(this.filtersActive, 'filterByUser', userIds);
			this.filtering = !this.queryUser.length;
		},

		updateNumCells() {
			const windowWidth = window.innerWidth;
			const sideMenuSize = !this.isCollapsed ? 213 : 0;

			if (windowWidth >= 1900 + sideMenuSize ) {
				this.numCells = 19;
			} else if (windowWidth >= 1600 + sideMenuSize) {
				this.numCells = 15;
			}	else if (windowWidth >= 1360 + sideMenuSize) {
				this.numCells = 8;
			} else if (windowWidth >= 1060 + sideMenuSize) {
				this.numCells = 5;
			} else {
				this.numCells = 3;
			}
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;
				const routeQuery = this.$route.query;
				const payload = {
					DataHoraUltimaAtualizacaoInicio: filters?.filterDate?.inicio || routeQuery?.DataHoraUltimaAtualizacaoInicio,
					DataHoraUltimaAtualizacaoFim: filters?.filterDate?.fim || routeQuery?.DataHoraUltimaAtualizacaoFim,
					usuariosIdsCompartilhado: filters?.filterByUser || routeQuery?.usuariosIdsCompartilhado,
					size: 10,
					SearchNome: filters?.filterSearch || routeQuery?.searchNomeOuId,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getCredentials(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
			} finally {
				this.isLoadingTable = false;
			}
		},

		async applyFilter(dataFiltered, query) {
			this.page = 1;
			this.filtering = true;
			this.clearFilteringActive = true;
			this.scrollInfinite = true;
			this.dataTable = dataFiltered;
			this.$store.commit('setFilters', query);
		},

		async clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearFilteringActive = false;
			this.filtersActive = {};
			this.queryDate = {
				inicio: '',
				fim: '',
			};
			this.queryUser = [],
			this.scrollInfinite = true;
			this.componentKey ++;
			this.applyedFilters = [];

			await this.$router.replace({'query': null}).catch(() => {});

			this.initPageCredentials();
		},

		async filterByDateSelected(start, end) {
			if (start === 'Invalid date') start = '';
			if (end === 'Invalid date') end = '';

			this.queryDate = { inicio: start, fim: end };
			this.filtering = (this.queryDate.inicio == '' && this.queryDate.fim == '');


			this.addOrRemoveFilter(this.filtersActive, 'filterDate', this.queryDate);
		},
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},
		goToCredential(id) {
			this.$router.push({
				name: 'credentialDetailing',
				params: { id },
			}).catch(()=>{});
		},
		async loadTable() {

			const payload = { size: this.pageSize, page: 1 };
			this.isLoadingTable = true;
			await this.restApi().getCredentials(this.workspace.id, payload)
				.then((response) => {
					this.dataTable = response;
				});
			this.isLoadingTable = false;
		},
		editCredential (credential: ICredentialsResponse) {
			this.$store.dispatch('ui/openExisitngCredential', { id: credential.id});
			this.$telemetry.track('User opened Credential modal', { credential_type: credential.type, source: 'primary_menu', new_credential: false, workflow_id: this.$store.getters.workflowId });
		},

		openModalAlert(data) {
			this.showAlert = true;
			this.excludeData = data;
		},

		async deleteCredential (credential: ICredentialsResponse) {
			this.isLoadingAction = true;
W
			try {
				await this.$store.dispatch('credentials/deleteCredential', {id: credential.id, workspaceId: this.workspace.id});
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('credentialsList.showMessage.message', { interpolate: { savedCredentialName: credential.nome }}),
					status: 'success',
				});

				this.showAlert = false;

				let credentialItem = this.dataTable.findIndex(item => item.id === credential.id);
				this.dataTable.splice(credentialItem, 1);

			} catch (error) {
				console.error(error);
				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error',
				});
			}

			this.isLoadingAction = false;
		},

		async loadNodeTypes(): Promise<void> {
			const nodeTypes = await this.restApi().getNodeTypes();

			this.$store.commit('setNodeTypes', nodeTypes);
		},
		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},
		pagination() {
			this.page++;
			const payload = { ...this.$route.query, size: this.pageSize, page: this.page };
			this.restApi().getCredentials(this.workspace.id, payload).then((response) => {
				for (const index in response) {
					this.dataTable.push(response[index]);
				}

				if (response.length < 10) {
					this.scrollInfinite = false;
				}
			});
		},

		async initPageCredentials() {
			this.isLoadingPage = true;

			if (this.previousRoute.name == 'credentialDetailing') {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			} else {
				await this.getCredentialsList();
			}
			this.isLoadingPage = false;
		},

		async getCredentialsList(options: { sizeInfinite: Boolean }) {
			if (options?.sizeInfinite) {
				this.isLoadingTable = true;
				this.pageSize = 99999;
			}
			const payload = { size: this.pageSize, page: 1 };
			await this.restApi().getCredentials(this.workspace.id, payload).then((response) => {
				this.dataTable = response;
				if (options?.sizeInfinite) {
					this.pageSize = 10;
					this.scrollInfinite = false;
					response.forEach((credential) => {
						if (credential.permissao == 'Editar') {
							credential.canShare = this.canShareCredential(credential),
							credential.selectedCredential = true;
						}
					});

					const credentialsSelected = this.dataTable.filter(credential => credential.selectedCredential);
					this.arrayCredentialsSelected = JSON.parse(JSON.stringify(credentialsSelected));
					this.$root.$emit('selectAll', JSON.parse(JSON.stringify(credentialsSelected)), {active: true, object: 'Credencial'});
					this.isLoadingTable = false;

				} else if (response.length < 10) {
					this.scrollInfinite = false;
				}
			});
		},
		showModal() {
			this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
		},
	},
	watch: {
		isCollapsed(newValue) {
			this.updateNumCells();
		},
	},
	beforeDestroy() {
		this.$root.$off("reloadList");
		window.removeEventListener('resize', this.updateNumCells);
	},
});
